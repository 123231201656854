import React from "react"
import SEO from "../components/seo"
import { Center, BodyContainer } from '../layouts/styles'
import PageTransition from 'gatsby-plugin-page-transitions';

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const About = ({ data }) => {
  const aboutData = data.allContentfulAbout.nodes[0]
  const dataAsHtml = documentToReactComponents(aboutData.textContent.json)
  return (
    <div>
      <SEO title="About" />
      <BodyContainer>
        {dataAsHtml}
      </BodyContainer>
    </div>
  )
}

export const data = graphql`
  query MyAboutQuery {
    allContentfulAbout {
      nodes {
        textContent {
          json
        }
        title
      }
    }
  }
`

export default About
